import styled from 'styled-components'
import { SFText, SFSVG } from '@otsofintech/sofinx-ui'

export const LanguageButton = styled(SFSVG)`
  &:hover {
    fill: ${ props => props.theme.palette.grey40 };
  }
`

export const Content = styled.div`
  position: absolute;
  top: 52px;
  right: 0px;
  box-shadow: 0px 8px 24px -4px ${props => props.theme.palette.defaultShadow1}, 0px 6px 12px -6px ${props => props.theme.palette.defaultShadow2};
  border-radius: 8px;
  background-color: ${ props => props.theme.palette.white };
`

export const Option = styled(SFText)`
  padding: 8px 16px;
	width: 100%;
  min-width: max-content;
	text-align: center;
  transition: all .2s;

  &:hover {
    color: ${ props => props.theme.palette.grey60 };
  }
`
