import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Container = styled.div`
  position: relative;
  cursor: pointer;

  &:hover:before {
    content: '';
    position: absolute;
    top: 36px;
    right: 0px;
    width: 248px;
    height: 24px;
    opacity: 0;
  }

  &:hover .menu {
    // 97 + item amount * 48
    height: 336px;
  }

  & > .menu.close {
    height: 0 !important;
  }
`

export const UserName = styled.div`
  padding: 8px 12px;
  width: 176px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${ props => props.theme.palette.grey100 };
  background-color: ${ props => props.theme.palette.grey10 };
  height: 36px;
`

export const Menu = styled.div`
  position: absolute;
  top: 58px;
  right: 0;
  width: 248px;
  height: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: ${ props => props.theme.shadow[600] };
  transition: all .2s;
  background-color: ${ props => props.theme.palette.white };
`

export const MenuList = styled.div`
  margin: 16px;
`

export const MenuItem = styled(NavLink)`
  margin: 8px 0;
  display: block;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: all .2s;
  cursor: pointer;
  &:hover {
    background-color: ${ props => props.theme.palette.primary }1A;
  }

  // active
  &.active-menu {
    background: ${ props => props.theme.palette.primary }1A;

    & * {
      color: ${ props => props.theme.palette.grey50 } !important;
    }
  }

  &.active-menu path {
    fill: ${ props => props.theme.palette.primary };
  }
`

export const Logout = styled.div`
  padding: 16px;
  border-top: 1px solid ${ props => props.theme.palette.grey20 };
`

export const LogoutItem = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: all .2s;
  cursor: pointer;

  &:hover {
    background-color: ${ props => props.theme.palette.warn2 }33;

    path {
      fill: ${ props => props.theme.palette.warn2 }
    }
  }
`
