import styled from 'styled-components'
import { SFIconImage, SFText } from '@otsofintech/sofinx-ui'
import { Link } from 'react-router-dom'

export const MainHeader = styled.header<{ isLogin: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  padding: ${ props => props.isLogin ? '0 32px' :'0 16px' };
  display: flex;
  align-items: center;
  height: 64px;
  background-color: ${ props => props.theme.palette.white };
  border-bottom: 1px solid ${ props => props.theme.palette.grey20 };
  z-index: 20;
`

export const Logo = styled(SFIconImage)`
  cursor: pointer;
  margin-right: 12px;
`

export const LoginNav = styled(Link)`
  font-size: 12px;
  color: ${ props => props.theme.palette.grey200 };
`

export const Meet = styled.a`
  margin-left: 48px;
  color: ${ props => props.theme.palette.grey50 };
  font-size: 14px;
`

export const SeperateLine = styled.div`
  border-left: 1px solid ${ props => props.theme.palette.grey100 };
  height: 10px;
  margin: 0 12px;

`

export const MySignalText = styled(SFText)`
   @media(max-width:515px){
     display: none;
   }

`

