import { toCamel, toUnderline, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './api-helper'

// 以Email註冊
export const RegisterByEmailService = (query: Auth.RegisterByEmail.Req): ApiService<Auth.RegisterByEmail.Res> => {
  const data = toUnderline(deepCopy(query))
  if (!data.referrer) delete data.referrer

  return request.post('/auth/register_by_email', data)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('RegisterByEmailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 以Phone註冊
export const RegisterByPhoneService = (query: Auth.RegisterByPhone.Req): ApiService<Auth.RegisterByPhone.Res> => {
  const data = toUnderline(deepCopy(query))
  if (!data.referrer) delete data.referrer

  return request.post('/auth/register_by_phone', data)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('RegisterByPhoneService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 以Email登入 (請求 email 登入驗證碼)
export const LoginByEmailService = (query: Auth.LoginByEmail.Req): ApiService<Auth.LoginByEmail.Res> => {
  const body = toUnderline(deepCopy(query))
  return request.post('/auth/login_by_email', body)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('LoginByEmailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 以Phone登入 (請求 phone 登入驗證碼)
export const LoginByPhoneService = (query: Auth.LoginByPhone.Req): ApiService<Auth.LoginByPhone.Res> => {
  const body = toUnderline(deepCopy(query))
  return request.post('/auth/login_by_phone', body)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('LoginByPhoneService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 綁定email
export const BindEmailService = (query: Auth.BindEmail.Request): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.post('/auth/email', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('BindEmailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 驗證碼
export const VerifyTokenService = (query: Auth.VerifyToken.Req): ApiService<Auth.VerifyToken.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.patch('auth/verify', params)
    .then(res => ({ isError: false, value: toCamel(res.data.user) }))
    .catch(err => {
      apiErrorMsg('VerifyTokenService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 綁定email驗證碼
export const BindEmailVerifyService = (query: Auth.Verify.Request): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.patch('/auth/email/verify', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('BindEmailVerifyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 登出
export const LogoutService = (): ApiService<boolean> => {
  return request.delete('/auth/logout')
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('LogoutService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 創建Websocket連接認證token，認證後才可訂閱 private 頻道，token有效時間為 10 秒
export const WsTokenService = (): ApiService<Auth.WsTokenCreate.Res> => {
  return request.post('/auth/ws_token')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('WsTokenCreateService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 輸入手機取得國碼
export const GetCountryCodeService = (): ApiService<Auth.GetCountryCode.Response> =>{
  return request.get('/auth/ip_to_country')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCountryCodeService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}