import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserInfo } from '@/utils/class'
import { GetUserService } from '@/services/user'

// extraReducers
const updateUserinfo = createAsyncThunk('user/info', async () => {
  const result = await GetUserService()
  return result.isError === false ? UserInfo(result.value) : UserInfo()
})

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: UserInfo(),
  reducers: {
    setUserInfo: (state, action: { payload: Common.User }) => {
      return UserInfo(action.payload)
    },
    clearUserInfo: (state) => {
      return UserInfo()
    }
  },

  extraReducers: (builder) => {
    builder.addCase(updateUserinfo.fulfilled, (state, action) => action.payload || UserInfo())
  },
})

export const userinfoActions =  { ...userInfoSlice.actions, updateUserinfo}
export default userInfoSlice.reducer
