import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store'
import { clearUserInfo, clearAuth } from '@/store/actions'
import { LogoutService } from '@/services/auth'
import { SFText, SFSVG, SFAutoEllipsis } from '@otsofintech/sofinx-ui'
import { loader } from '@/hooks/useLoading'
import UserIcon from '@/assets/images/user.svg'
import SignalIcon from '@/assets/images/signal.svg'
import ReferralIcon from '@/assets/images/referral.svg'
import LogoutIcon from '@/assets/images/logout.svg'
import AssetsIcon from '@/assets/images/stack.svg'
import ReportIcon from '@/assets/images/report.svg'
import AccountIcon from '@/assets/images/person_outline.svg'
import { Container, UserName, Menu, MenuList, MenuItem, Logout, LogoutItem } from './style'

const UserMenu = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const username = useSelector((state: RootState) => state.userInfo.username)

  const menuList = [
    { label: t('accountSettings'), name: 'account-settings', icon: AccountIcon },
    { label: t('signalManage'), name: 'signal', icon: SignalIcon },
    { label: t('assets'), name: 'user-assets', icon: AssetsIcon },
    { label: t('report'), name: 'report', icon: ReportIcon },
    { label: t('referral'), name: 'referral', icon: ReferralIcon },
  ]

  // click to close menu
  const menuNode = useRef<HTMLDivElement>(null)
  const handleClick = () => {
    menuNode.current?.classList.add('close')
    setTimeout(() => { menuNode.current?.classList.remove('close') }, 300)
  }

  // logout
  const handleLogout = async () => {
    loader.load()
    const result = await LogoutService()
    if (result.value) {
      dispatch(clearAuth())
      dispatch(clearUserInfo())
    }
    loader.unload()
  }

  return (
    <Container>
      <UserName>
        <SFSVG style={{ height: '20px', width: '20px', marginRight: '12px' }} src={UserIcon} color="grey200" />
        <SFText level={1} color="grey40">
          <SFAutoEllipsis width="110px" value={username} tooltip />
        </SFText>
      </UserName>

      <Menu ref={menuNode} className="menu">
        <MenuList>
          { menuList.map(item => (
            <MenuItem
              onClick={handleClick}
              to={`/${item.name}`}
              key={item.name}
              activeClassName="active-menu"
            >
              <SFSVG src={item.icon} color="grey200" />
              <SFText level={2} ml="16px" color="grey40">{item.label}</SFText>
            </MenuItem>
          )) }
        </MenuList>

        <Logout>
          <LogoutItem onClick={handleLogout}>
            <SFSVG src={LogoutIcon} color="grey200" />
            <SFText level={2} ml="16px" color="grey40">{t('logout')}</SFText>
          </LogoutItem>
        </Logout>
      </Menu>
    </Container>
  )
}

export default UserMenu
