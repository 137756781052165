import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { EntryInfoService } from '@/services/entry'

const initialState = {
  id: '',
  name: '',
  domain: '',
  apiDomain: '',
  wsDomain: '',
  siteName: '',
  favicon: '',
  lightLogo: '',
  hyperlinkContext: '',
  hyperlinkUrl: '',
  hyperlinkHide: true,
  enable: false,
  certificateIsReady: false,
  ingressIsReady: false,
  createdAt: '',
  updatedAt: '',
}

const fakeSofinEntryState = {
  id: 1,
  name: 'sofinx',
  domain: 'sp.sofinx.com',
  apiDomain: 'api-sp.sofinx.com',
  wsDomain: '',
  siteName: '',
  favicon: '',
  lightLogo: '',
  hyperlinkContext: '',
  hyperlinkUrl: '',
  hyperlinkHide: false,
  enable: true,
  certificateIsReady: true,
  ingressIsReady: true,
  createdAt: '',
  updatedAt: '',
}

const updateSiteInfo = createAsyncThunk('site/entry', async () => {
  const result = await EntryInfoService()
  const state = Number(process.env.IS_SOFIN_ENTRY) === 0 ? initialState : fakeSofinEntryState
  return !result.isError ? (result.value.entry || state) : state
})


const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(updateSiteInfo.fulfilled, (state, action) => action.payload as Common.Entry)
  }
})

export const siteActions = { ...siteSlice.actions, updateSiteInfo}
export default siteSlice.reducer
