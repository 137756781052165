import { userinfoActions } from './slices/userInfoReducer'
import { authActions } from './slices/authReducer'
import { walletActions } from './slices/walletReducer'
import { symbolsActions } from './slices/symbolsReducer'
import { wsStateActions } from './slices/wsStateReducer'
import { siteActions } from './slices/siteReducer'

export const setUserInfo = userinfoActions.setUserInfo

export const clearUserInfo = userinfoActions.clearUserInfo

export const updateUserinfo = userinfoActions.updateUserinfo

export const setAuth = authActions.setAuth

export const clearAuth = authActions.clearAuth

export const updateUserWallet = walletActions.updateUserWallet

export const clearWallet = walletActions.clearWallet

export const updateSymbol = symbolsActions.updateConfigSymbol

export const clearSymbol = symbolsActions.clearConfigSymbol

export const enableWsState = wsStateActions.enable

export const disableWsState = wsStateActions.disable

export const updateSiteInfo = siteActions.updateSiteInfo
