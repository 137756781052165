import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RootState } from '@/store'
import LightLogo from '@/assets/images/freeze-color/logo.svg'
import SignalIcon from '@/assets/images/signal.svg'
import { MainHeader, Logo, LoginNav, SeperateLine, MySignalText } from './style'
import { UserAuth } from '@/utils/enum'
import I18nSelector from '../i18n-selector'
import UserMenu from '../user-menu'
import { SFButton, SFSVG } from '@otsofintech/sofinx-ui'

const HeaderBar = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const site = useSelector((state: RootState) => state.site)

  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)
  const isLoginPage = useMemo(() => history.location.pathname === '/login', [history.location.pathname])

  return (
    <MainHeader isLogin={isLogin}>
      
      <Logo onClick={() => history.push('/')} src={site.lightLogo} defaultSrc={LightLogo} width="93px" height="32px" />
      {/* { site.hyperlinkHide === false && isLogin && <Meet href={site.hyperlinkUrl} target="_blank">{site.hyperlinkContext || t('meet')}</Meet> } */}
      { isLogin && 
      <NavLink to='/signal' style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
        <SFSVG src={SignalIcon} width='24px' height='24px' color='primary' />
        <MySignalText level={1} fontWeight={600} color='primary' ml='6px'>{t('mySignal')}</MySignalText>
      </NavLink>}
      { isLogin && 
      <SeperateLine/>
      }
      <I18nSelector />
      <SeperateLine/>
      { !isLogin ?
        <>
          <LoginNav to={ isLoginPage ? '/register' : '/login' }>{t( isLoginPage ? 'register' : 'login')}</LoginNav>
          { site.hyperlinkHide === false && <SFButton ml="24px" themecolor="primary" onClick={() => window.open(site.hyperlinkUrl, '__blank')}>{site.hyperlinkContext || t('meet')}</SFButton> }
        </> : null
      }
      { isLogin ? <UserMenu /> : null }
    </MainHeader>
  )
}

export default HeaderBar
