import { lazy, Suspense, useCallback, useMemo } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { UserAuth } from '@/utils/enum'
import Layout from '@/pages/layout'
import AuthRoute from './AuthRoute'
import Loading from './loading'

const NotFound = lazy(() => import(/* webpackChunkName: "error-not-found" */ '@otsofintech/sofinx-ui/lib/components/SFErrorNotFound'))
const Login = lazy(() => import(/* webpackChunkName: "login" */ '@/pages/login'))
const Register = lazy(() => import(/* webpackChunkName: "register" */ '@/pages/register'))
const LandingPage = lazy(() => import(/* webpackChunkName: "landing" */ '@/pages/landing'))
const CreateSignal = lazy(() => import(/* webpackChunkName: "create-signal" */ '@/pages/create-signal'))
const LinkSignal = lazy(() => import(/* webpackChunkName: "link-signal" */ '@/pages/link-signal'))
const Signal = lazy(() => import(/* webpackChunkName: "signal" */ '@/pages/signal-list'))
const SignalDetail = lazy(() => import(/* webpackChunkName: "signal-detail" */ '@/pages/signal-detail'))
const SymbolList = lazy(() => import(/* webpackChunkName: "symbol-list" */ '@/pages/signal-detail/symbol-list'))
const Referral = lazy(() => import(/* webpackChunkName: "referral" */ '@/pages/referral/index'))
const UserAssets = lazy(() => import(/* webpackChunkName: "user-assets" */ '@/pages/user-assets'))
const Report = lazy(() => import(/* webpackChunkName: "report" */ '@/pages/report'))
const Withdraw = lazy(() => import(/* webpackChunkName: "withdraw" */ '@/pages/withdraw'))
const Account = lazy(() => import(/* webpackChunkName: "account" */ '@/pages/account-settings'))
const CreateBankWithrawalAccount = lazy(() => import(/* webpackChunkName: "create-bank-withrawal-account" */ '@/pages/account-settings/edit-bank'))
const EditBankWithrawalAccount = lazy(() => import(/* webpackChunkName: "edit-bank-withrawal-account" */ '@/pages/account-settings/edit-bank/_id'))
const CreateCryptoWithrawalAccount = lazy(() => import(/* webpackChunkName: "create-crypto-withrawal-account" */ '@/pages/account-settings/edit-crypto'))
const EditCryptoWithrawalAccount = lazy(() => import(/* webpackChunkName: "edit-crypto-withrawal-account" */ '@/pages/account-settings/edit-crypto/_id'))
const KycApplication = lazy(() => import(/* webpackChunkName: "kycApplication" */ '@/pages/account-settings/components/kyc/kycApplication' ))
const WithoutEmail = lazy(()=> import(/* webpackChunkName: "without-email" */ '@/pages/without-email'))



const authMap = {
  public: [ UserAuth.GUEST, UserAuth.MEMBER ],
  guest: [ UserAuth.GUEST ],
  member: [ UserAuth.MEMBER ],
}

const Router = () => {
  const auth = useSelector((state: RootState) => state.auth)
  const checkAuth = useCallback((authList: UserAuth[]) => authList.includes(auth), [auth])

  const userInfo = useSelector((state: RootState) => state.userInfo)
  const isEmailOk = useMemo(() =>  !userInfo.id || userInfo.email , [userInfo])

  return (
    <BrowserRouter>
      <Loading />
      <Layout>
        <Suspense fallback={null}>
          <Switch>
            <AuthRoute exact path="/login" component={Login} auth={checkAuth(authMap.guest)} to='/signal' />
            <AuthRoute path="/register" component={Register} auth={checkAuth(authMap.guest)} to='/signal' />
            <AuthRoute exact path="/" component={LandingPage} auth={checkAuth(authMap.public)}/>

            <AuthRoute exact path="/without-email" component={WithoutEmail} auth={checkAuth(authMap.member) && !isEmailOk } to="/"/>

            <AuthRoute exact path="/signal" component={Signal} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/signal/:id" component={SignalDetail} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/signal/:id/symbol-list" component={SymbolList} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/referral" component={Referral} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/create-signal" component={CreateSignal} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/link-signal/:id" component={LinkSignal} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/user-assets" component={UserAssets} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/report" component={Report} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/withdraw" component={Withdraw} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/account-settings" component={Account} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/account-settings/edit-bank" component={CreateBankWithrawalAccount} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/edit-bank" component={CreateBankWithrawalAccount} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/edit-bank/:id" component={EditBankWithrawalAccount} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/edit-crypto" component={CreateCryptoWithrawalAccount} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/edit-crypto/:id" component={EditCryptoWithrawalAccount} auth={checkAuth(authMap.member)}/>
            <AuthRoute exact path="/account-settings/kyc-application" component={KycApplication} auth={ checkAuth(authMap.member)}/>

            <AuthRoute path="*" component={NotFound} auth={checkAuth(authMap.public)}/>
          </Switch>
        </Suspense>
      </Layout>
    </BrowserRouter>
  )
}

export default Router
