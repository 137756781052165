import { createGlobalStyle } from 'styled-components'
import { consoleProject, onErrorEvent } from '@otsofintech/sofinx-ui/lib/helper'
import { initLanguage } from '@/assets/locales/i18n'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const middleware = () => {
  dayjs.extend(utc)

  initSentry()
  onErrorEvent()
  initLanguage()
  consoleProject()
}

function initSentry () {
  const isProd = /^.*\.sofinx.com$/.test(window.location.host)
  if (isProd) {
    Sentry.init({
      dsn: 'https://0f22bdf1ccf8497790bd010bec91ce4e@o1361986.ingest.sentry.io/6653122',
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    })
  }
}

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    height: 100%;
    min-height: 100vh;

    cursor: default;
  }

  * {
    /* 避免mui或其他外部組件覆蓋字體 */
    font-family: Inter, -apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;

    box-sizing: border-box;
    text-decoration: none;

    &:disabled {
      cursor: not-allowed;
    }
  }

  ::selection {
    background: ${ props => props.theme.palette.extend1 };
  }
`
