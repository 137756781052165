import { toCamel, toUnderline, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './api-helper'

// 檢查username是否存在
export const GetCheckUsernameService = (query: User.CheckUsername.Req): ApiService<User.CheckUsername.Res> => {
  const params = toUnderline(deepCopy(query))

  return request.get('/user_check/username', { params })
    .then(res => ({ isError: false, value: res.data.exists }))
    .catch(err => {
      apiErrorMsg('GetCheckUsernameService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 檢查email是否存在
export const GetCheckEmailService = (query: User.CheckEmail.Req): ApiService<User.CheckEmail.Res> => {
  const params = toUnderline(deepCopy(query))

  return request.get('/user_check/email', { params })
    .then(res => ({ isError: false, value: res.data.exists }))
    .catch(err => {
      apiErrorMsg('GetCheckEmailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 檢查phone是否存在
export const GetCheckPhoneService = (query: User.CheckPhone.Req): ApiService<User.CheckPhone.Res> => {
  const params = toUnderline(deepCopy(query))

  return request.get('/user_check/phone', { params })
    .then(res => ({ isError: false, value: res.data.exists }))
    .catch(err => {
      apiErrorMsg('GetCheckPhoneService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 用戶資訊
export const GetUserService = (): ApiService<Common.User> => {
  return request.get('/user')
    .then(res => ({ isError: false, value: toCamel(res.data.user) }))
    .catch(err => {
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得用戶推薦人
export const GetReferralService = (): ApiService<User.GetReferral.Res> => {
  return request.get('/user/referral')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetReferralService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 更改語系
export const PutUserLangService = (query: User.PutUserLanguage.Req): ApiService<boolean> => {
  const body = toUnderline(deepCopy(query))
  return request.put('/user/language', body)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('PutUserLangService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// Get User Kyc Status
export const GetUserKycStatusService = (): ApiService<User.CheckKyc.Res> => {
  return request.get('/user/kyc/status')
    .then(res => {
      return { isError: false, value: toCamel(res.data) } as const
    })
    .catch(err => {
      apiErrorMsg('GetUserKycStatusService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// Sumsub(第三方服務) Web SDK Access Token
export const PostUserKycAccessToken = (): ApiService<User.PostUserKycToken.Res> => {
  return request.post('user/kyc/access_token').then(res => ({ isError: false, value: toCamel(res.data) })).catch(err => {
    apiErrorMsg('PostUserKycAccessToken',err.response?.data?.error)
    return { isError: true, value: err.response?.data?.error }
  })

}
