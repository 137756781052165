import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store'
import { setUserInfo } from '@/store/actions'
import { PutUserLangService } from '@/services/user'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Collapse from '@material-ui/core/Collapse'
import LanguageIcon from '@/assets/images/language.svg'
import { UserAuth } from '@/utils/enum'
import { languageList } from '@/assets/locales/i18n'
import { LanguageButton, Content, Option } from './style'

const I18nSelector = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const currentLang = useMemo(() => {
    return languageList.find(item => item.value === i18n.language)?.value || '-'
  }, [i18n.language])

  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)
  const userInfo = useSelector((state: RootState) => state.userInfo)

  const handleOption = (lang: string) => {
    handleClose()
    localStorage.setItem('language', lang)
    i18n.changeLanguage(lang)

    if (isLogin) {
      PutUserLangService({ language: lang })
      dispatch(setUserInfo({ ...userInfo, language: lang }))
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => setIsOpen(prev => !prev)
  const handleClose = () => setIsOpen(false)

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div style={{ position: 'relative', marginLeft: isLogin ? '' : 'auto', display: 'flex', alignItems: 'center'}}>
        <LanguageButton
          src={LanguageIcon}
          color={ isOpen ? 'grey40' : 'grey200' }
          pointer
          onClick={handleToggle}
        />

        <Content>
          <Collapse in={isOpen}>
            <div style={{ padding: '8px 0'}}>
              { languageList.map(item => (
                <Option
                  display="inline-block"
                  level={2}
                  color={ currentLang === item.value ? 'primary' : 'grey40' }
                  fontWeight={ currentLang === item.value ? 600 : 400 }
                  textAlign="center"
                  key={item.value}
                  onClick={() => handleOption(item.value)}
                  pointer
                >
                  {item.text}
                </Option>
              ))}
            </div>
          </Collapse>
        </Content>
      </div>
    </ClickAwayListener>
  )
}

export default I18nSelector
