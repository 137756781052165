export enum Platform { MT4 = 'MT4', MT5 = 'MT5', BINANCE = 'BINANCE' }

/**
 * 角色 |
 * Guest: 未登入 |
 * Member: 登入(權限1) |
 */
export enum UserAuth { GUEST = 'GUEST', MEMBER = 'MEMBER' }

/**
 * 登入註冊方式
 */
export enum SignTypes { Email, Phone }

/**
 * 資金紀錄type
 */
export enum RealWalletAssetsType {
  WITHDRAW_WIRE = 'WITHDRAW_WIRE',
  WITHDRAW_CRYPTO = 'WITHDRAW_CRYPTO',
  PROFIT_SHARE = 'PROFIT_SHARE',
  WITHDRAW_BY_SYSTEM = 'WITHDRAW_BY_SYSTEM',
  DEPOSIT_BY_SYSTEM = 'DEPOSIT_BY_SYSTEM'
}

/**
 * 出金紀錄 type
 */
export enum RealWalletWithdrawType {
  WIRE = 'WIRE',
  CRYPTO = 'CRYPTO',
}
/**
 * SOF 資金紀錄type
 */
export enum TokenWalletType {
  REWARD = 'REWARD'
}

export enum RewardType {
  REWARD_REGISTERED = 'REWARD_REGISTERED',
  REWARD_FIRST_SIGNAL_CREATE = 'REWARD_FIRST_SIGNAL_CREATE',
  REWARD_FOLLOWING_REACH_TEN = 'REWARD_FOLLOWING_REACH_TEN',
  REWARD_INTRODUCE = 'REWARD_INTRODUCE',
}
