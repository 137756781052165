import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetAssetWalletService } from '@/services/assets'
import { Wallet } from '@/utils/class'

const initWallet = Wallet()

const updateUserWallet = createAsyncThunk('asset/wallet', async () => {
  const result = await GetAssetWalletService({payment: 'withdraw'})
  return !result.isError ? result.value : initWallet
})

const walletSlice = createSlice({
  name: 'wallet',
  initialState: initWallet,
  reducers: {
    clearWallet: () => initWallet,
  },

  extraReducers: (builder) => {
    builder.addCase(updateUserWallet.fulfilled, (state, action) => {
      if (action.payload) return action.payload
    })
  },
})

export const walletActions = { ...walletSlice.actions, updateUserWallet}
export default walletSlice.reducer
