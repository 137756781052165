import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userInfoReducer from './slices/userInfoReducer'
import authReducer from './slices/authReducer'
import walletReducer from './slices/walletReducer'
import symbolsReducer from './slices/symbolsReducer'
import wsStateReducer from './slices/wsStateReducer'
import siteReducer from './slices/siteReducer'

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  auth: authReducer,
  wallet: walletReducer,
  symbols: symbolsReducer,
  wsState: wsStateReducer,
  site: siteReducer,
})

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>

export default store
