import { toCamel } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './api-helper'

// 取得交易品種清單
export const SymbolList = (): ApiService<Api.Symbol.SymbolList.Res> => {
  return request.get('/symbol')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('SymbolList', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
