import { Route, Redirect } from 'react-router-dom'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
interface AuthRouteProps {
  path: string
  component: React.LazyExoticComponent<any>
  exact?: true
  auth?: boolean
  to?: string
  hasEmail?: boolean
}

const AuthRoute = ({path, component: Component, exact, auth = true, to = '/login'}: AuthRouteProps) => {
  const attrs = {
    exact: exact === true,
  }
  const userInfo = useSelector((state: RootState) => state.userInfo)

  /// 尚未取得user資料(未完成登入程序) or 已取得email
  const isEmailOk = useMemo(() =>  !userInfo.id || userInfo.email , [userInfo])

  return <Route {...attrs} path={path} render={() => auth ? isEmailOk || path === '/without-email' ? <Component /> : <Redirect to='/without-email' /> : <Redirect to={to} /> } />
}

export default AuthRoute