import { useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { UserAuth } from '@/utils/enum'
import HeaderBar from './header-bar'
import Message from './message'

const Wrapper = styled.div<{ isLogin: boolean, isLandingPage: boolean }>`
  width: 100%;
  min-width: ${ props => props.isLogin && !props.isLandingPage ? '1288px' : null };
  background-color: ${ props => props.isLandingPage ? props.theme.palette.white : props.theme.palette.greyBG };
`

const Main = styled.main<{ isLogin: boolean, email: boolean, isLandingPage: boolean }>`
  margin: 0 auto;
  padding-top: ${props => (props.isLogin && props.email) || props.isLandingPage ? '64px' : '0'};
  width: ${ props => (props.isLogin && props.email  && !props.isLandingPage)  ? '1080px' : '100%' };
  min-height: 100vh;
`

const Layout = (props: any) => {
  const location =  useLocation()
  useEffect(() => window.scrollTo(0, 0), [location])

  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)
  const email = useSelector((state: RootState) => state.userInfo.email)
  const isLandingPage = location.pathname === '/'

  return (
    <Wrapper isLogin={isLogin} isLandingPage={isLandingPage}>
      {!isLogin && <HeaderBar/> }

      {isLogin && Boolean(email) && 
        <HeaderBar/> 
      }  

      <Main isLogin={isLogin} email={Boolean(email)} isLandingPage={isLandingPage}>
        {props.children}
      </Main>

      <Message />
    </Wrapper>
  )
}

export default Layout
