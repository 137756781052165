import { request, apiErrorMsg, ApiService } from './api-helper'
import { toCamel } from '@otsofintech/sofinx-ui/lib/helper'

export const EntryInfoService = (): ApiService<Entry.Info.Res> => {
  return request.get('/entry')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('EntryInfoService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
