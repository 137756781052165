import { toCamel, toUnderline, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './api-helper'
import { RealWalletAssetsType, TokenWalletType } from '@/utils/enum'

// 取得使用者錢包餘額
export const GetAssetWalletService = (query?: Asset.Wallet.Req): ApiService<Asset.Wallet.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/asset/wallet', { params })
    .then(res => {
      return { isError: false, value: toCamel(res.data.wallet) } as const
    })
    .catch(err => {
      apiErrorMsg('GetUserWalletService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 查詢使用者資金紀錄
export const GetWalletRealRecord = (query: Asset.WalletRecord.Req = {}): ApiService<Asset.WalletRecord.Res<RealWalletAssetsType>> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/asset/wallet/real/record',  { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetWalletRealRecord', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 查詢使用者SOF資金紀錄
export const GetWalletTokenRecord = (query?: Asset.WalletRecord.Req): ApiService<Asset.WalletRecord.Res<TokenWalletType>> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/asset/wallet/token/record',  { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetWalletTokenRecord', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 下載資產資金紀錄
export const GetWalletRealRecordDownload = (): ApiService<File> => {
  return request.get('/asset/wallet/real/record_download')
    .then(res => ({ isError: false, value: res.data }))
    .catch(err => {
      apiErrorMsg('GetWalletRealRecordDownload', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 下載資產SOF紀錄
export const GetWalletTokenRecordDownload = (): ApiService<File> => {
  return request.get('/asset/wallet/token/record_download')
    .then(res => ({ isError: false, value: res.data }))
    .catch(err => {
      apiErrorMsg('GetWalletTokenRecordDownload', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
