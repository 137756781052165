import { getDefaultLanguage } from '@/assets/locales/i18n'
import { Platform } from './enum'

function ObjectCombine<T extends Object> (rawValue: T, value?: Partial<T>): T {
  type Keys = keyof typeof value

  if (value === undefined || value === null) return rawValue

  if (Object.prototype.toString.call(rawValue) !== '[object Object]') return value as T
  else {
  	(Object.keys(rawValue) as Keys[]).forEach(key => {
  		if (rawValue.hasOwnProperty(key)) rawValue[key] = ObjectCombine(rawValue[key], value[key])
  	})
  }

  return rawValue
}

export const UserInfo = (value?: Common.User): Common.User => {
  const result = {
    id: '',
    headshot: '',
    username: '',
    email: '',
    countryCode: '',
    phone: '',
    language: getDefaultLanguage(),
    signalInfo: {
      passed: 0,
    },
  }

  return ObjectCombine(result, value)
}

export const Signal = (value?: Common.Signal): Common.Signal => {
  const result: Common.Signal = {
    id: '',
    token: '',
    name: '',
    describe: '',
    headshot: '',
    status: 'PASSED' as Common.SignalStatus,
    enable: false,
    comment: '',
    platform: '' as Platform,
    createdAt: '',
    updatedAt: '',
    submittedAt: '',
    reviewedAt: '',
    enabledAt: '',
    computed: {
      numOfFollowers: 0,
      investmentOfFollow: '',
      wasFollowed: false,
      profitOfCompletedFollowing: '',
      profitOfSymbolsStrategy: '',
      profitShare: '',
      runningRobotData: [],
      totalTradingRobotData: [],
      followersDatas: [],
    },
    info: {
      number: '',
      server: '',
      timezone: 0,
      stopMtcp: false,
      reason: '',
    },
    followSetting: {
      investmentMin: '',
      investmentMax: '',
      profitSharePercent: '',
    },
    performance: {
      principal: '',
      profit: '',
      drawdown: '',
      drawdownRatio: '',
      duration: '',
      tradePerWeek: '',
      avgHoldingDuration: '',
      apr7: '',
      apr30: '',
      apr180: '',
      sharpeRatio: '',
      volMax: '',
      volMin: '',
      volAvg: '',
      volTotal: '',
      winRate: '',
      tradesTotal: 0,
      updatedAt: '',
      profitDatas: [],
      averageProfit: '',
      averageLoss: '',
      rateOfReturn: '',
    },
    symbols: [],
    connStatus: 'DISCONNECTED',
    connMethod: '' as Common.ConnectMethod,
    enabledBefore: false,
  }

  return ObjectCombine(result, value)
}

export const Wallet = (value?: Common.Wallet):Common.Wallet => {
  const result = {
    balance: '0',
    tokenBalance: '0',
    frozenAmount: '0',
    withdrawableBalance: '0',
  }

  if (value) {
    if (value.balance) result.balance = value.balance
    if (value.tokenBalance) result.tokenBalance = value.tokenBalance
  }

  return result
}

export const ProfitShareRecord = (value?: Common.ProfitShareRecord): Common.ProfitShareRecord => {
  const result = {
    id: '',
    signalId: '',
    signalName: '',
    robotId: '',
    robotName: '',
    followType: '' as any,
    settleTime: '',
    profit: '',
    hwm: '',
    profitShare: '',
    fee: '',
    receive: '',
    status: '' as any,
  }

  return ObjectCombine(result, value)
}
export const WithdrawOrderDetail = (value?: Common.Withdraw.WithdrawOrderDetail): Common.Withdraw.WithdrawOrderDetail => {
  const result = {
    id: '',
    userId: '',
    username: '',
    type: 'WIRE',
    status: 'UNDER_REVIEW',
    amount: '',
    paidAmount: '',
    comment: '',
    fee: '',
    exchangeRate: '',
    currency: '',
    currencyType: 'FIAT',
    appliedAt: '',
    cancelledAt: '',
    complianceId: '',
    complianceAccount: '',
    approvedAt: '',
    rejectedAt: '',
    financeId: '',
    financeAccount: '',
    completedAt: '',
    paymentSlip: '',
    transferInfoForWire: {},
    estimatedReceiveAmount: '',
  } as Common.Withdraw.WithdrawOrderDetail

  return ObjectCombine(result, value)
}
