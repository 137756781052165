import { ThemeProvider } from 'styled-components'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import Router from '@/routes/index'
import { UserAuth } from '@/utils/enum'
import { setUserInfo, setAuth, clearWallet, updateUserWallet, updateSymbol, clearSymbol, updateSiteInfo } from '@/store/actions'
import { LoadingInto, SFErrorBoundary } from '@otsofintech/sofinx-ui'
import { theme } from '@otsofintech/sofinx-ui/lib/style'
import { GetUserService } from '@/services/user'
import { webSocket } from '@/services/api-helper/webSocket'
import { middleware, GlobalStyle } from './middleware'

middleware()

const App = () => {
  const { i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch()
  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)

  // get user info
  useEffect(() => {
    getUserInfo()
    getGlobalData()

    function getGlobalData () {
      dispatch(updateSiteInfo())
    }

    async function getUserInfo () {
      const result = await GetUserService()
      if (!result.isError) {
        changeLanguage(result.value.language)
        dispatch(setUserInfo(result.value))
        dispatch(setAuth(UserAuth.MEMBER))
      }
      setIsLoading(false)
    }

    // set language
    function changeLanguage (language: string) {
      if (language === i18n.language) return
      localStorage.setItem('language', language)
      i18n.changeLanguage(language)
    }
  }, [])

  useEffect(() => {
    if (isLogin) getData()
    else clearData()

    function clearData () {
      dispatch(clearWallet())
      dispatch(clearSymbol())
    }

    function getData () {
      dispatch(updateUserWallet())
      dispatch(updateSymbol())
    }
  }, [isLogin])

  // websocket
  useEffect(() => webSocket.create(), [])
  useEffect(() => { isLogin ? webSocket.getAuth() : webSocket.clearAuth() }, [isLogin])

  return (
    <ThemeProvider theme={theme}>
      <SFErrorBoundary>
        <GlobalStyle />
        { isLoading ? <LoadingInto /> : <Router /> }
      </SFErrorBoundary>
    </ThemeProvider>
  )
}

export default App
